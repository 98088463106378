import {
  Fragment,
  forwardRef,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';

import { getDOMProps } from 'app/shared/utils/react';
import styled, { css } from 'styled-components';
import useForkRef from '@mui/utils/useForkRef';
import useId from '@mui/utils/useId';
import { AdornmentButton } from 'app/shared/u21-ui/components/input/text-field/AdornmentButton';
import {
  ClearButton,
  Loading,
  StartIconContainer,
} from 'app/shared/u21-ui/components/input/text-field/styles';
import { DebouncedInput } from 'app/shared/u21-ui/components/input/text-field/DebouncedInput';
import { IconEye, IconEyeOff } from '@u21/tabler-icons';
import { InputAdornment, TextField } from '@mui/material';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { U21EncryptedTextField } from 'app/shared/u21-ui/components/input/text-field/U21EncryptedTextField';
import {
  U21TextFieldProps,
  TYPES,
  INPUT_TYPES,
  INVALID_KEYS,
} from 'app/shared/u21-ui/components/input/text-field/U21TextFieldInputProps';

export const U21TextField = forwardRef<HTMLDivElement, U21TextFieldProps>(
  (
    {
      autoFocus = false,
      clearable = true,
      delay = 300,
      disabled,
      endIcon,
      error,
      focused,
      id: idProp,
      InputLabelProps = {},
      inputProps = {},
      InputProps = {},
      inputRef,
      label,
      loading = false,
      minRows = 2,
      onChange,
      onBlur,
      onKeyDown,
      onKeyPress,
      placeholder,
      required,
      responsiveLength,
      size = 'small',
      startIcon,
      textTransform,
      type = TYPES.TEXT,
      value,
      valueTransform,
      name,
      encryptedFieldProps,
      ...rest
    },
    ref,
  ) => {
    const id = useId(idProp);
    const isTextArea = type === TYPES.TEXTAREA;
    const isNumber = type === TYPES.NUMBER || type === TYPES.INTEGER;
    const hasClearIcon =
      clearable && !disabled && (value === 0 || Boolean(value));

    const [showPassword, setShowPassword] = useState(false);

    const internalInputRef = useRef<HTMLInputElement>(null);
    const combinedInputRef = useForkRef(inputRef, internalInputRef);

    const defaultEndAdornment = useMemo(() => {
      const adornments = [
        hasClearIcon && (
          <ClearButton
            key="clear"
            onClick={(e) => {
              onChange(undefined, e);
              internalInputRef.current?.focus();
            }}
          />
        ),
        loading && <Loading key="loading" loading />,
        type === TYPES.PASSWORD && (
          <AdornmentButton
            key="reveal"
            aria-label={showPassword ? 'Hide Password' : 'Show Password'}
            icon={
              showPassword ? <IconEyeOff size={20} /> : <IconEye size={20} />
            }
            onClick={() => setShowPassword(!showPassword)}
          />
        ),
        <Fragment key="end-icon">{endIcon}</Fragment>,
      ].filter(Boolean);
      if (adornments.length > 0) {
        return <InputAdornment position="end">{adornments}</InputAdornment>;
      }
      return undefined;
    }, [
      endIcon,
      hasClearIcon,
      loading,
      onChange,
      setShowPassword,
      showPassword,
      type,
    ]);

    const defaultStartAdornment = useMemo(() => {
      if (startIcon) {
        return (
          <InputAdornment position="start">
            <StartIconContainer $disabled={disabled}>
              {startIcon}
            </StartIconContainer>
          </InputAdornment>
        );
      }
      return undefined;
    }, [disabled, startIcon]);

    const [key, forceUpdate] = useReducer((x) => x + 1, 0);

    if (encryptedFieldProps) {
      return (
        <U21EncryptedTextField
          onChange={onChange}
          ref={ref}
          id={id}
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          required={required}
          responsiveLength={responsiveLength}
          textTransform={textTransform}
          disabled={disabled}
          error={error}
          encryptedFieldProps={encryptedFieldProps}
        />
      );
    }

    return (
      <StyledTextField
        $loading={loading}
        $responsiveLength={responsiveLength}
        $textarea={isTextArea}
        $textTransform={textTransform}
        autoFocus={autoFocus}
        disabled={disabled}
        error={error}
        focused={focused}
        fullWidth={!responsiveLength}
        id={id}
        inputProps={{
          delay,
          element: type === TYPES.TEXTAREA ? TextareaAutosize : undefined,
          inputKey: key,
          responsiveLength,
          ...(type === TYPES.TEXTAREA ? { minRows } : {}),
          ...inputProps,
          onWheel: (e) => {
            if (isNumber) {
              e.currentTarget.blur();
            }
            inputProps.onWheel?.(e);
          },
        }}
        InputLabelProps={{ htmlFor: id, id: `${id}-label`, ...InputLabelProps }}
        InputProps={{
          ...InputProps,
          inputComponent: DebouncedInput,
          endAdornment: InputProps.endAdornment || defaultEndAdornment,
          startAdornment: InputProps.startAdornment || defaultStartAdornment,
        }}
        inputRef={combinedInputRef}
        label={label}
        multiline={isTextArea}
        name={name}
        onBlur={(e) => {
          onBlur?.(e);
          if (valueTransform) {
            const transformedValue = valueTransform(e.target.value);
            // force update is needed if the value isn't changed so react doesn't
            // rerender causing the input element showing an outdated value
            if (value === transformedValue && e.target.value !== value) {
              forceUpdate();
            }
            onChange(transformedValue, e);
          }
        }}
        onChange={(e) => {
          const { value: newValue } = e.target;

          if (isNumber) {
            onChange(newValue ? Number(newValue) : undefined, e);
            return;
          }
          if (textTransform === 'uppercase') {
            onChange(newValue.toUpperCase(), e);
          } else if (textTransform === 'lowercase') {
            onChange(newValue.toLowerCase(), e);
          } else {
            onChange(newValue, e);
          }
        }}
        onKeyPress={(e) => {
          if (
            (INVALID_KEYS.includes(e.key) && isNumber) ||
            (e.key === '.' && type === TYPES.INTEGER)
          ) {
            e.preventDefault();
          }
          onKeyPress?.(e);
        }}
        onKeyDown={(e) => {
          if (isNumber && (e.key === 'ArrowDown' || e.key === 'ArrowUp')) {
            e.preventDefault();
          }
          onKeyDown?.(e);
        }}
        ref={ref}
        required={required}
        placeholder={placeholder}
        size={size}
        type={
          INPUT_TYPES[
            type === TYPES.PASSWORD && showPassword ? TYPES.TEXT : type
          ]
        }
        value={value ?? ''}
        // use ref value instead because select + datepickers don't use value prop
        // title is not needed for textarea
        title={isTextArea ? undefined : internalInputRef.current?.value}
        {...getDOMProps(rest)}
      />
    );
  },
);

interface TextFieldStyleProps {
  $loading?: boolean;
  $responsiveLength?: boolean;
  $textarea?: boolean;
  $textTransform?: U21TextFieldProps['textTransform'];
}

const StyledTextField = styled(TextField)<TextFieldStyleProps>`
  .MuiInputBase-root {
    min-height: 40px;
    ${(props) =>
      props.$textarea
        ? css`
            padding: 8.5px 6px;
          `
        : css`
            padding: 0 6px;
          `}

    ${(props) => {
      const { $loading, disabled } = props;
      let cursor;
      if ($loading) {
        cursor = 'progress';
      } else if (disabled) {
        cursor = 'not-allowed';
      }

      return (
        cursor &&
        css`
          cursor: ${cursor};

          .MuiInputBase-input {
            cursor: ${cursor};
          }
        `
      );
    }}
  }

  .MuiInputBase-input {
    ${(props) =>
      !props.InputProps?.startAdornment &&
      css`
        padding-left: 6px;
      `}

    ${(props) =>
      props.$textarea
        ? css`
            resize: vertical;
          `
        : css`
            line-height: 40px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          `}

    ${(props) =>
      props.$textTransform
        ? css`
            text-transform: ${props.$textTransform};

            ::placeholder {
              text-transform: none;
            }
          `
        : css``}
  }

  .MuiInputAdornment-root {
    height: unset;
    max-height: unset;
  }

  /* make space between adornment + text 8px total */
  .MuiInputAdornment-positionEnd {
    margin-left: 4px;
  }

  .MuiInputAdornment-positionStart {
    margin-right: 4px;
  }

  .Mui-focused ${ClearButton} {
    visibility: visible;
  }

  :hover {
    ${ClearButton} {
      visibility: visible;
    }
  }

  .MuiFormLabel-asterisk {
    color: ${(props) => props.theme.palette.error.main};
  }

  /* fix bug introduced by node_modules/antd/dist/antd.css where legend is set to 100% */
  ${(props) =>
    !props.label &&
    css`
      .MuiOutlinedInput-notchedOutline > legend {
        width: 0;
      }
    `}

  ${(props) =>
    props.$responsiveLength
      ? css`
          max-width: 100%;
        `
      : css``}
`;
