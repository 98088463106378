import {
  forwardRef,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { U21Loading } from 'app/shared/u21-ui/components/display/U21Loading';
import { consoleWarn } from 'app/shared/utils/console';
import { useTheme } from '@mui/styles';
import {
  U21TextFieldProps,
  U21EncryptedFieldProps,
  TYPES,
} from 'app/shared/u21-ui/components/input/text-field/U21TextFieldInputProps';

import { U21LoadError } from 'app/shared/u21-ui/components/display/U21LoadError';

interface U21EncryptedTextFieldProps
  extends Pick<
    U21TextFieldProps,
    | 'delay'
    | 'disabled'
    | 'error'
    | 'id'
    | 'inputProps'
    | 'name'
    | 'onChange'
    | 'placeholder'
    | 'required'
    | 'responsiveLength'
    | 'textTransform'
    | 'type'
    | 'value'
  > {
  // Encrypted field props are required for this component
  encryptedFieldProps: U21EncryptedFieldProps;
}

export const U21EncryptedTextField = forwardRef<
  HTMLDivElement,
  U21EncryptedTextFieldProps
>(
  (
    {
      delay = 300,
      disabled,
      error,
      id,
      inputProps = {},
      name,
      onChange,
      placeholder,
      required,
      responsiveLength,
      textTransform,
      type,
      value,
      encryptedFieldProps,
    },
    ref,
  ) => {
    const [iframeReady, setIframeReady] = useState(false);
    const [iFrameLoadError, setIFrameLoadError] = useState(false);
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const accessToken = encryptedFieldProps.authTokenFetcher();
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const theme = useTheme();
    const isTextArea = type === 'textarea';

    useEffect(() => {
      // Set timeout for iframe loading
      timeoutRef.current = setTimeout(() => {
        if (!iframeReady) {
          setIFrameLoadError(true);
        }
      }, 10000); // 10 second timeout
    }, [iframeReady]);

    useEffect(() => {
      if (!iframeReady) return;

      // Add function to extract styles
      const getInputStyles = () => {
        const styles = {
          'min-height': '40px',
          'text-overflow': 'ellipsis',
          border: `1px solid ${error ? theme.palette.error.main : theme.palette.divider}`,
          'border-radius': `${theme.shape.borderRadius}px`,
          '-webkit-tap-highlight-color': 'transparent',
          'font-size': '1rem',
          'touch-action': 'manipulation',
          '-webkit-font-smoothing': 'antialiased',
          'font-variant-numeric': 'tabular-nums',
          'font-feature-settings': '\'tnum\', "tnum"',
          'font-family': theme.typography.fontFamily,
          'font-weight': theme.typography.fontWeightRegular,
          'background-color': disabled
            ? theme.palette.action.disabledBackground
            : theme.palette.background.paper,
          color: disabled
            ? theme.palette.text.disabled
            : theme.palette.text.primary,
          resize: 'none',
          'text-transform': textTransform || 'none',
          ...(type === TYPES.TEXTAREA
            ? {
                height: 'calc(100% - 40px)',
                width: 'calc(100% - 20px)',
                padding: '8.5px 6px 8.5px 12px',
                'white-space': 'pre-wrap',
              }
            : {
                height: '40px',
                width: '100%',
                padding: '0 6px',
                'line-height': '40px',
                'white-space': 'nowrap',
              }),
        };
        return Object.entries(styles)
          .map(([cssKey, cssValue]) => `${cssKey}: ${cssValue}`)
          .join('; ');
      };

      const setupMessage = {
        type: 'SETUP_INPUT_FIELD',
        fieldId: id,
        fieldType: type,
        placeholder,
        required,
        disabled,
        minLength: inputProps?.minLength,
        maxLength: inputProps?.maxLength,
        token: value,
        authToken: accessToken,
        style: getInputStyles(),
        formStyle:
          'overflow: hidden; margin: 0; padding: 0; height: 100%; width: 100%;',
        invalidStyle: `border-color: ${theme.palette.error.main};`,
        // Taken from semantic ui
        selectionStyle:
          'background-color: rgba(100, 100, 100, .4); color: rgba(0, 0, 0, .87);',
      };

      iframeRef.current?.contentWindow?.postMessage(
        setupMessage,
        encryptedFieldProps.iframeHost,
      );
    }, [
      iframeReady,
      theme,
      id,
      placeholder,
      required,
      type,
      error,
      disabled,
      inputProps,
      textTransform,
      accessToken,
      value,
      encryptedFieldProps,
    ]);

    const iFrameLoaded = useCallback(() => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setIframeReady(true);
    }, []);

    useEffect(() => {
      let changeTimeout: NodeJS.Timeout;
      const handleMessage = (event: MessageEvent) => {
        if (event.data?.type === 'INPUT_READY' && event.data?.fieldId === id) {
          // do nothing for now.
        }

        if (
          event.data?.type === 'TOKENIZED_VALUE' &&
          event.data?.token &&
          event.data?.fieldId === id
        ) {
          // Update the value of the input
          const syntheticEvent = {
            target: {
              name,
              value: event.data.token,
            },
          };
          onChange(
            event.data.token,
            syntheticEvent as unknown as SyntheticEvent,
          );
        }

        if (
          event.data?.type === 'INPUT_CHANGED' &&
          event.data?.fieldId === id
        ) {
          // Clear the timeout if it exists to avoid multiple calls
          clearTimeout(changeTimeout);

          // Wait half a second and force an "auto save"
          changeTimeout = setTimeout(() => {
            iframeRef.current?.contentWindow?.postMessage(
              {
                type: 'GET_TOKENIZED_VALUE',
                fieldId: id,
                authToken: accessToken,
              },
              encryptedFieldProps.iframeHost,
            );
          }, delay);
        }
      };

      window.addEventListener('message', handleMessage);
      return () => window.removeEventListener('message', handleMessage);
    }, [id, name, onChange, accessToken, delay, encryptedFieldProps]);

    const isLoading = !iframeReady && !iFrameLoadError;

    // Let people know if there's an issue.
    useEffect(() => {
      if (!name) {
        consoleWarn('Encrypted field must have a name');
      }
    }, [name]);

    return (
      <>
        <U21Loading loading={isLoading} />
        <EncryptedFieldContainer
          $responsiveLength={responsiveLength}
          $loading={isLoading}
          $textarea={isTextArea}
          $error={iFrameLoadError}
          ref={ref}
        >
          {!iFrameLoadError && (
            <EncryptedIframe
              ref={iframeRef}
              title={`Encrypted Input ${id}`}
              src={`${encryptedFieldProps.iframeHost}/encrypt-field/${id}?field_type=${type}`}
              onLoad={iFrameLoaded}
            />
          )}
        </EncryptedFieldContainer>
        {iFrameLoadError && (
          <U21LoadError
            label="encrypted field"
            onTryAgain={() => window.location.reload()}
          />
        )}
      </>
    );
  },
);

interface TextFieldStyleProps {
  $loading?: boolean;
  $error?: boolean;
  $responsiveLength?: boolean;
  $textarea?: boolean;
}

const EncryptedFieldContainer = styled.div<TextFieldStyleProps>`
  position: relative;
  width: ${(props) => (props.$responsiveLength ? 'auto' : '100%')};
  resize: ${(props) => (props.$textarea ? 'vertical' : 'none')};
  display: ${(props) => {
    if (props.$loading || props.$error) {
      return 'none';
    }
    if (props.$textarea) {
      return 'flex';
    }
    return 'block';
  }};
  padding: 0;
  margin: 0;
  height: 80vh;
  max-width: 100%;
  visibility: ${(props) => (props.$loading ? 'hidden' : 'visible')};
  overflow: hidden;
`;

const EncryptedIframe = styled.iframe`
  border: none;
  width: 100%;
  min-height: 40px;
  height: 100%;
  border: 0;
  padding: 0;
  margin: 0;
  flex-grow: 1;
  background: transparent;
  overflow: hidden;
  display: block;
`;
